.icon {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
  
  .icon img {
    width: 64px;
    height: 64px;
  }
  