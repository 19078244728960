@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&family=Roboto:wght@400&display=swap');

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  background-color: rgb(0, 117, 0);
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
}

.taskbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  background-color: #c0c0c0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #808080;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.start-button {
  background-color: #c0c0c0;
  border: 2px solid #808080;
  color: black;
  font-family: 'Tahoma', sans-serif;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 10px;
}

.start-menu {
  position: absolute;
  bottom: 40px;
  left: 5px;
  width: 200px;
  background-color: #c0c0c0;
  border: 1px solid #000000;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.start-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.start-menu li {
  padding: 5px;
  border-bottom: 1px solid #808080;
  cursor: pointer;
}

.start-menu li a, .start-menu li button {
  text-decoration: none;
  color: black;
  font-family: 'Tahoma', sans-serif;
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
}

.start-menu li:hover {
  background-color: #000080;
  color: white;
}

.start-menu li a:hover, .start-menu li button:hover {
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #c0c0c0;
  border: 2px solid #808080;
  width: 350px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000080;
  color: white;
  padding: 5px;
}

.modal-title {
  font-family: 'Tahoma', sans-serif;
  font-size: 12px;
}

.modal-close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.modal-body {
  padding: 10px;
  background-color: #c0c0c0;
}

.modal-body audio {
  width: 100%;
  background-color: #c0c0c0;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.cmd-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cmd-window {
  background-color: #000000;
  border: 2px solid #808080;
  width: 500px;
  height: 300px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.cmd-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000080;
  color: white;
  padding: 5px;
}

.cmd-title {
  font-family: 'Tahoma', sans-serif;
  font-size: 12px;
}

.cmd-close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.cmd-body {
  padding: 10px;
  color: #00FF00;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  white-space: pre-wrap;
  overflow-y: auto;
}

.win95-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.win95-window {
  background-color: #c0c0c0;
  border: 2px solid #808080;
  width: 400px;
  height: 500px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.win95-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000080;
  color: white;
  padding: 5px;
}

.win95-title {
  font-family: 'Tahoma', sans-serif;
  font-size: 12px;
}

.win95-close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.win95-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.win95-image {
  max-width: 100%;
  max-height: 80%;
  margin-bottom: 10px;
}

.win95-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.win95-controls button {
  background-color: #c0c0c0;
  border: 2px solid #808080;
  font-family: 'Tahoma', sans-serif;
  cursor: pointer;
  padding: 5px 10px;
  flex: 1;
  margin: 0 5px;
}

.win95-controls button:hover {
  background-color: #000080;
  color: white;
}
