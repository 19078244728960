.win95-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .win95-window {
    background-color: #c0c0c0;
    border: 2px solid #808080;
    width: 400px;
    height: 400px; /* Adjusted height for a square window */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
  }
  
  .win95-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000080;
    color: white;
    padding: 2px 5px;
  }
  
  .win95-title {
    font-family: 'Tahoma', sans-serif;
    font-size: 12px;
  }
  
  .win95-close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }
  
  .win95-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow: auto; /* Ensure content is scrollable if it overflows */
  }
  
  .win95-content img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  